import React from "react";

const UserManualModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Modal only renders when isOpen is true

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center">
      <div className="relative w-full h-full md:w-3/4 md:h-3/4 lg:w-2/3 lg:h-2/3 bg-white p-5 overflow-y-auto rounded-md shadow-lg">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-3xl text-gray-700 hover:text-gray-900"
        >
          ✖
        </button>

        {/* Modal content */}
        <div>
          <h1 className="text-2xl font-bold mb-4">User Manual</h1>
          <p>
            Welcome to the user manual. Here, you can provide detailed information about how to use your product or service.
          </p>
          <p className="mt-4">
            Add as much content as needed. This area will scroll if it gets too long.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserManualModal;
