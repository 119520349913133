import React, {useState} from 'react';
import { motion } from 'framer-motion';

// Combined list of all features
const allFeatures = [
  "12 degree ambient temperature 🌡️ difference making heating and cooling highly effective.",
  "Builtin appliances and induction plates for comfortable living and cooking.",
  "Composite waterproof floorings.",
  "Laminam worktops.",
  "Hidden compartments.",
  "Safety compartment glove box with highly sophisticated safety tools and weapons.",
  "Smart safe for storage.",
  "Day time running LEDs lights across the chassis.",
  "Unique chassis number with identity 🆔 transfer.",
  "Low-e double glazed protected glass with extra glare protection.",
  "Moon roof with automatic retractable cover.",
  "Surveillance remote built-in camera views with 360 and 24/7 Monitoring.",
  "Automatic curtains.",
  "Voice command controlled.",
  "Builtin HVAC.",
  "Entertainment screen for viewing pleasure.",
  "Ultra comfortable bedding and pillows.",
  "Composite materials and luxury interior.",
  "Theft protection and safety lock.",
  "External power weatherproof ports for outside usage.",
  "Raised platform.",
  "Easily transportable through long wheel base vehicles.",
  "50+ years life guaranteed.",
  "Instructions manual with full company support.",
  "After sales maintenance services.",
  "Emergency Dial phones with GPS locators.",
  "Concealed luxury washroom German fittings.",
  "Mood lighting.",
  "Smoke detectors and alarms.",
  "Water and sewerage and power ports available for easy attachments.",
  "Luxury German kitchen.",
  "Luxury coffee table and chairs.",
  "Luxury bed.",
  "Cross ventilation windows with tilt and turn mechanism and safety mesh.",
  "Card access system.",
  "Remote monitoring.",
  "Tesla Cell wall (additional feature) for complete off-grid living.",
  "Pool attachment (additional add-on feature).",
  "Storage wardrobe.",
  "Storage for extra items.",
  "Outside storage for extra baggage.",
  "Balcony version available (additional feature).",
  "Available in two bedroom and single bedroom models.",
  "Sustainable and recyclable.",
  "Zero water used in direct manufacturing.",
  "80 percent of the materials used are naturally sourced.",
  "Lowest maintenance cost.",
  "Robust and agile expandable framing with rollover safety.",
  "Richter scale 10 earthquake resistance.",
  "Extreme temperatures withstanding.",
  "Rustproof.",
  "Electrical safety switches for enhanced protection.",
  "Provided with First Aid ⛑️ Box.",
  "Panic 🔘 Button.",
  "Fire alarms & fire extinguishers for passive fire safety.",
  "Survival Kit.",
  "Produced directly inline keeping in view the UN sustainable development Goals 2030."
];



// Animation settings
const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };
  
  const Features = () => {
    const [expanded, setExpanded] = useState(false); // State to track whether features are shown
  
    // Toggles the expanded state
    const toggleFeatures = () => {
      setExpanded(!expanded);
    };
  
    return (
      <section className="py-10 bg-gray-100">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-10">Features</h2>
          <button 
            onClick={toggleFeatures} 
            className="mb-5 text-white bg-black hover:bg-grey font-bold py-4 px-16 rounded"
          >
            {expanded ? 'Show Less' : 'Show More'}
          </button>
          <div className={`grid grid-cols-1 md:grid-cols-2 gap-3 ${expanded ? '' : 'hidden'}`}>
            {allFeatures.map((feature, index) => (
              <motion.div 
                key={index} 
                className="p-4 bg-gray-400 bg-opacity-50 -lg shadow-lg hover:bg-gray-500 transition duration-300"
                style={{ minHeight: '70px' }} // Ensures all boxes are the same height
                initial="hidden"
                whileInView="visible"
                viewport={{ once: false, amount: 0.8 }}
                variants={itemVariants}
              >
                {feature}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default Features;