import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./components/Home";
import About from "./components/Home/About";
import GallerySection from "./components/Home/GallerySection";
import Interview from "./components/Home/Interview";
import Contact from "./components/Home/Contact";
import Footer from "./components/Home/Footer";
import UserManualModal from "./components/Usermanual"; // Ensure path is correct

const App = () => {
  const [isManualOpen, setManualOpen] = useState(false); // State to manage modal open/close

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/about", element: <About /> },
        { path: "/gallery", element: <GallerySection /> },
        { path: "/interview", element: <Interview /> },
        { path: "/contacts", element: <Contact /> },
        {
          path: "/footer",
          element: <Footer openManual={() => setManualOpen(true)} />, // Pass function to Footer
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />

      {/* Full-screen User Manual Modal */}
      <UserManualModal 
        isOpen={isManualOpen} 
        onClose={() => setManualOpen(false)} 
      />
    </>
  );
};

export default App;
